import React from "react"
import Container from "../components/container"
import Footer from "../components/footer"
import { Helmet } from "react-helmet"


export default () => (
    <Container>
        <Helmet>
            <title>Privacy | An Exquisite Meal</title>
            <meta name="description" content="View the privacy policy." />
        </Helmet>

        <div class="sub-container-page">
            <div class="body-content">
                <a href="/"><img src="../AnExquisiteMeal_headline.png" width="600" alt="An Exquisite Meal" /></a>

                <h2 class="headline">Privacy policy.</h2>
                <p>This website collects anonymous user data via cookies that are sent to Google Analytics and Amplitude for the purpose of optimizing site performance. Additionally, emails collected on this website via the newsletter subscription will be used to send you ocassional newsletters related to the film.</p>

                <p>Your email or personally identifiable information work
                will never be sold or shared with a third party.</p>

                
               

            </div>
        </div>
        <Footer>
        </Footer>
    </Container>

)

